import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  label: {
    defaultMessage: 'Best seller',
    description: 'Product badge. Mercado livre badge: best seller label',
  },
});

export function MercadoBestSeller(): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.icon}></div>
      <div className={styles.text}>{intl.formatMessage(messages.label)}</div>
    </div>
  );
}
