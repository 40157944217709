import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import styles from './index.module.scss';
import {SchemaProps} from './schema';

export type StarsRatingTestId = {
  value: unknown;
};

type Props = TestIdProp<StarsRatingTestId> & {
  rating: number;
  reviewCount?: number;
  schema?: 'aggregateRating' | 'reviewRating';
  withNumber?: boolean;
};

export function StarsRating({
  rating,
  schema,
  reviewCount,
  testId,
  withNumber = false,
}: Props): React.ReactElement | null {
  if (rating === 0) {
    return null;
  }

  const schemaProps = schema ? SchemaProps[schema] : undefined;
  const percent = (Math.floor(5 - rating) * 2 + 1 + (5 - rating) * 10) / 60;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={styles.rating} {...schemaProps} data-test-id={testId}>
      {schemaProps && (
        <>
          <meta content='1' itemProp='worstRating' />
          <meta content='5' itemProp='bestRating' />
        </>
      )}
      <div>
        <div className={styles.stars} style={{paddingRight: `${percent * 100}%`}} />
      </div>
      {withNumber && (
        <div className={styles.label} data-test-id={testId?.value} itemProp={schemaProps ? 'ratingValue' : undefined}>
          <FormattedNumber maximumFractionDigits={1} minimumFractionDigits={0} value={rating} />
        </div>
      )}
      {Boolean(!withNumber && schemaProps) && <meta content={String(rating)} itemProp='ratingValue' key='value' />}
      {Boolean(reviewCount && schemaProps) && <meta content={String(reviewCount)} itemProp='reviewCount' />}
    </div>
  );
}
