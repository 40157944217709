import cnb from 'classnames/bind';
import {Marginality, MarginalityType} from 'components/Marginality';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

const messages = defineMessages({
  platform: {
    defaultMessage: 'Mercado Livre',
    description: 'Badge with marginality info. Mercado Livre platform name',
  },
});

type MarginalityKind = 'text' | 'badge';
type Props = {
  kind?: MarginalityKind;
  type: MarginalityType;
  value: number;
};

export function MarginalityMercado({value, type, kind = 'text'}: Props): React.ReactElement {
  const intl = useIntl();
  const platformName = intl.formatMessage(messages.platform);

  return (
    <div className={cn(styles.container, `kind-${kind}`)}>
      <Marginality platform={platformName} type={type} value={value} />
    </div>
  );
}
