import classnames from 'classnames/bind';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const cn = classnames.bind(styles);

type Position = 'left' | 'right';

type Props = TestIdProp & {
  disabled: boolean;
  onClick: () => void;
  position: Position;
};

export const ArrowButton = ({position, onClick, testId, disabled}: Props): React.ReactElement => {
  return (
    <button
      className={cn(styles.button, {disabled, [position]: true})}
      data-test-id={testId}
      disabled={disabled}
      onClick={onClick}
      type='button'
    >
      <div className={styles.icon} />
    </button>
  );
};
