import classNamesBind from 'classnames/bind';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

const cn = classNamesBind.bind(styles);

const messages = defineMessages({
  label: {
    defaultMessage: 'Age-restricted product',
    description: 'Product for adults',
  },
});

type Props = {
  compact?: boolean;
};

export function Adult({compact}: Props): React.ReactElement {
  return (
    <div className={cn('adult', {compact})}>
      <div className={styles.description}>
        <FormattedMessage {...messages.label} />
      </div>
    </div>
  );
}
