import {CategoryHierarchy} from 'lib/analytics/gtm/types/category';
import {Category} from 'lib/category/types';

const LAST_CATEGORY_PROPERTY_INDEX = 5;

export function getCategoryHierarchy(category?: Category): CategoryHierarchy {
  const result: CategoryHierarchy = {};
  const categories = [category, ...(category?.parents ?? [])];
  const lastIndex = categories.length - 1;

  for (let i = 0; i < LAST_CATEGORY_PROPERTY_INDEX; i++) {
    const key = `item_category${i === 0 ? '' : i + 1}` as keyof CategoryHierarchy;
    result[key] = categories[lastIndex - i]?.name;
  }

  return result;
}
