import React from 'react';
import {ProductBadge, ProductBadgeTheme} from './ProductBadge';

type Props = {
  theme?: ProductBadgeTheme;
};

export function BrandBadge({theme}: Props): React.ReactElement {
  return <ProductBadge theme={theme}>Brand</ProductBadge>;
}
