import classNamesBind from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

export {NoticeItem} from './NoticeItem';

const cn = classNamesBind.bind(styles);

type Props = {
  accent?: boolean;
  children: React.ReactNode;
  compact?: boolean;
};

export function Notice({accent, children, compact}: Props): React.ReactElement {
  return (
    <div className={cn('notice', {accent, compact})}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
