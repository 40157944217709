import {getCategoryHierarchy} from 'lib/analytics/gtm/category';
import {Category} from 'lib/category/types';
import {ContentList, ItemContentType} from 'lib/contentList/types';
import {EcommerceItem, EcommerceViewItemListEvent} from '../types';

type EcommerceSearchResultsParams = {
  category?: Category;
  contentList: ContentList;
  listId?: string;
  listName?: string;
};

export function ecommerceSearchResultsEvent({
  category,
  contentList,
  listId,
  listName,
}: EcommerceSearchResultsParams): EcommerceViewItemListEvent {
  const items: EcommerceItem[] = [];

  contentList.forEach((item, index) => {
    if (item.type === ItemContentType.PRODUCT) {
      const product = item.content;

      items.push({
        index,
        item_id: product.id,
        item_list_id: listId,
        item_list_name: listName,
        item_name: product.name ?? '',
        ...getCategoryHierarchy(category),
      });
    }
  });

  return {
    ecommerce: {
      item_list_id: listId,
      item_list_name: listName,
      items,
    },
    event: 'view_item_list',
  };
}
