import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {assertNever} from '@joomcode/deprecated-utils/types';
import React, {useRef, useMemo, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Tooltip} from 'uikit/Tooltip';
import styles from './index.module.scss';

const messages = defineMessages({
  hint: {
    defaultMessage: 'Product margin relative to price on {platform}',
    description: 'Badge of marginality. Hint message',
  },
  range: {
    defaultMessage: 'Up to {value}',
    description: 'Badge of marginality. Label',
  },
  unit: {
    defaultMessage: 'mrg',
    description: 'Badge of marginality. Short sing of marginality',
  },
  value: {
    defaultMessage: '{value, number}%',
    description: 'Badge of marginality. Label',
  },
});

export type MarginalityType = 'default' | 'range';
export type Props = {
  platform?: React.ReactNode;
  type?: MarginalityType;
  value: number;
};

export function Marginality({value, platform, type = 'default'}: Props): React.ReactElement {
  const intl = useIntl();
  const tooltipState = usePopupState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const valueText = intl.formatMessage(messages.value, {value});
  const rangeText = intl.formatMessage(messages.range, {value: valueText});

  const content = useMemo(() => {
    switch (type) {
      case 'default':
        return valueText;
      case 'range':
        return rangeText;
      default:
        assertNever(type);
        return valueText;
    }
  }, [type, valueText, rangeText]);

  const handleMouseEnter = useCallback(() => {
    if (platform) {
      tooltipState.open();
    }
  }, [platform, tooltipState.open]);

  return (
    <>
      <div
        className={styles.container}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={tooltipState.close}
        ref={containerRef}
      >
        <div className={styles.icon}></div>
        <div className={styles.text}>
          <span>{content}</span>
          <span className={styles.unit}>{intl.formatMessage(messages.unit)}</span>
        </div>
      </div>
      {tooltipState.isOpen && platform && (
        <Tooltip placement='top' targetRef={containerRef}>
          <div className={styles.hint}>{intl.formatMessage(messages.hint, {platform})}</div>
        </Tooltip>
      )}
    </>
  );
}
