import {CartItem} from 'lib/cart/types';
import {EcommerceBeginCheckoutEvent, EcommerceItem} from '../types';

type Props = {
  cartItems: CartItem[];
};

export function ecommerceBeginCheckout({cartItems}: Props): EcommerceBeginCheckoutEvent {
  const items: EcommerceItem[] = cartItems.flatMap((item) =>
    item.variants.map((variant) => ({
      item_id: item.product.id,
      item_name: item.product.name ?? item.product.id,
      item_variant: variant.id,
      quantity: variant.quantity,
    })),
  );
  return {
    ecommerce: {
      items,
    },
    event: 'begin_checkout',
  };
}
