import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Notice, NoticeItem} from '../Notice';

const messages = defineMessages({
  label: {
    defaultMessage: 'Out of stock',
    description: 'Out of stock on product lite card',
  },
});

export function OutOfStock(): React.ReactElement {
  return (
    <Notice>
      <NoticeItem>
        <FormattedMessage {...messages.label} />
      </NoticeItem>
    </Notice>
  );
}
