import {ProductPriceRange} from 'events/types/ProductPriceRange';
import {ProductLite} from 'lib/productLite/types';

export function exportProductLitePriceRangeForAnalytics(
  priceRange: ProductLite['priceRange'],
): ProductPriceRange | undefined {
  return priceRange
    ? {
        ccy: priceRange.ccy,
        valRange: [priceRange.min, priceRange.max],
      }
    : undefined;
}
