import {Image} from 'components/Image';
import {Link} from 'components/Link';
import {BrandBadge, DiscountBadge, ProductBadgeFactory, SearchTagBadge} from 'components/ProductBadge';
import {Square} from 'components/Square';
import {StarsRating} from 'components/StarsRating';
import LockIcon from 'icons/lock.svg';
import {useAnalytics} from 'lib/analytics';
import {ecommerceProductClick} from 'lib/analytics/gtm/ecommerce';
import {useAppEnv} from 'lib/appEnv';
import {useElementPreview} from 'lib/hooks';
import {getObfuscatedCountValue} from 'lib/obfuscatedCount';
import {reverseUrl} from 'lib/router';
import {SearchTagType} from 'lib/searchTag/types';
import React, {forwardRef, useCallback, useRef} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {dataTestItem} from 'testId';
import {Adult} from './Adult';
import styles from './index.module.scss';
import {OutOfStock} from './OutOfStock';
import {SellerPriceInfo} from './SellerPriceInfo';
import {Props} from './types';
import {ProductLiteProps} from './types';
import {exportProductLitePriceRangeForAnalytics} from './utils';
import {WholesalePrice} from './WholesalePrice';

const messages = defineMessages({
  minAmount: {
    defaultMessage: 'Min {minAmount} pc',
    description: 'Minimum order quantity on the product card in the issue.',
  },
  optimalPrice: {
    defaultMessage: '{price} with delivery to the Russian Federation',
    description: 'Comment on the price on the product card in the search results.',
  },
  viewProduct: {
    defaultMessage: 'View product',
    description: '[button] button on hidden product',
  },
});

const ENABLED_SEARCH_TAGS = [SearchTagType.TOP_PRODUCT, SearchTagType.HOT_PRICE];

function LockedCard({signIn}: {signIn?: () => void}): React.ReactElement {
  const intl = useIntl();

  return (
    <button className={styles.blur} onClick={signIn} type='button'>
      <div className={styles.locked}>
        <LockIcon className={styles.lockedIcon} />
      </div>
      <div className={styles.blurButton}>{intl.formatMessage(messages.viewProduct)}</div>
    </button>
  );
}

const ProductLiteBase = forwardRef<HTMLDivElement, ProductLiteProps>(
  (
    {alwaysShowPrice, onClick, gridColumn, noRating = false, product, testId, forAuthorizedOnly, signIn},
    ref,
  ): React.ReactElement => {
    const productUrl = reverseUrl.product({productId: product.id});
    const {discount, minAmount, badges, sellerPrice, marginPercent} = product;
    const reviewsCount = product.reviewsCount && getObfuscatedCountValue(product.reviewsCount);

    const forAdultsVisible = Boolean(product.forAdults);
    const outOfStockVisible = !product.inStock;
    const discountVisible = Boolean(discount);
    const brandVisible = Boolean(product.brand);
    const badgesVisible = discountVisible || brandVisible;
    const ratingVisible = Boolean(!noRating && product.rating);
    const minAmountVisible = Boolean(minAmount);
    const withProductBadges = Boolean(badges && badges.length > 0);

    return (
      <div
        className={styles.product}
        data-test-id={testId}
        data-test-item={dataTestItem(product.id)}
        ref={ref}
        style={{gridColumn}}
      >
        {forAuthorizedOnly && <LockedCard signIn={signIn} />}
        <Link className={styles.content} href={productUrl} onClick={onClick} target='_blank' testId={testId?.link}>
          <Square>
            <div className={styles.imagePlace}>
              <div className={styles.imageWrap}>
                {product.mainImage ? (
                  <Image className={styles.image} loading='lazy' sizes='200px' src={product.mainImage} />
                ) : (
                  <div className={styles.emptyContent} />
                )}
              </div>
            </div>
            {Boolean(product.promoted) && <div className={styles.adBadge}>AD</div>}
            {Boolean(product.countryFlag) && (
              <Image className={styles.countryFlag} loading='lazy' sizes='32px' src={product.countryFlag} />
            )}
            {outOfStockVisible && (
              <div className={styles.notice}>
                <OutOfStock />
              </div>
            )}
            {forAdultsVisible && <Adult />}
            <div className={styles.searchBadgesWrap}>
              {product.searchTags?.map((item) =>
                ENABLED_SEARCH_TAGS.includes(item.tag) ? (
                  <div className={styles.searchBadge} key={item.tag}>
                    <SearchTagBadge tag={item} />
                  </div>
                ) : null,
              )}
            </div>
            {withProductBadges && (
              <div className={styles.productBadges}>
                {badges?.map((badge) => <ProductBadgeFactory badge={badge} key={badge} />)}
              </div>
            )}
          </Square>
          {badgesVisible && (
            <div className={styles.badges}>
              {discountVisible && <DiscountBadge discount={discount!} theme='filled' />}
              {brandVisible && <BrandBadge />}
            </div>
          )}
          <div className={styles.info}>
            <div className={styles.price}>
              <WholesalePrice alwaysShowPrice={alwaysShowPrice} price={product.optimalPrice} />
            </div>
            {(sellerPrice || marginPercent) && (
              <SellerPriceInfo marginPercent={marginPercent} sellerPrice={sellerPrice} />
            )}
            {minAmountVisible && (
              <div className={styles.minAmount}>
                <FormattedMessage {...messages.minAmount} values={{minAmount}} />
              </div>
            )}
            {ratingVisible && (
              <div className={styles.rating}>
                <StarsRating rating={product.rating!} reviewCount={reviewsCount} />
              </div>
            )}
            <div className={styles.name}>
              {product.brand && (
                <React.Fragment>
                  <strong data-test-id={testId?.brandName}>{product.brand.name}</strong>{' '}
                </React.Fragment>
              )}
              <span data-test-id={testId?.name}>{product.name}</span>
            </div>
          </div>
        </Link>
      </div>
    );
  },
);

export function ProductLite({
  listId,
  listName,
  index,
  product,
  position,
  category,
  promotionId,
  ...restProps
}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const rootRef = useRef(null);

  const handleContentClick = useCallback(() => {
    analytics.dataLayer(ecommerceProductClick({category, listId, listName, position: index, product}));
    analytics.sendEvent({
      payload: {
        index,
        pageUrl: window.location.href,
        position,
        productId: product.id,
        promotionId,
        searchResultsUniqId: product.searchResultUniqId,
        source: appEnv.currentPageName,
        timeBeforeClick: window.performance.now(),
      },
      type: 'productClick',
    });
  }, [analytics, product, position, index, appEnv.currentPageName]);

  useElementPreview(rootRef, () => {
    analytics.sendEvent({
      payload: {
        badges: product.searchTags?.length ? product.searchTags.map(({tag}) => tag) : undefined,
        index,
        minQuantity: product.minAmount,
        pageUrl: window.location.href,
        position,
        productId: product.id,
        productPriceRange: exportProductLitePriceRangeForAnalytics(product.priceRange),
        promotionId,
      },
      type: 'productPreview',
    });
  });

  return (
    <ProductLiteBase {...restProps} category={category} onClick={handleContentClick} product={product} ref={rootRef} />
  );
}
