import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  direction: 'left' | 'right';
  visible: boolean;
};

export function Shadow({visible, direction}: Props): React.ReactElement {
  return (
    <div
      className={cn(styles.shadow, direction === 'left' ? styles.shadowLeft : styles.shadowRight, {
        [styles.shadowShow]: visible,
      })}
    />
  );
}
