import {CartItem} from 'lib/cart/types';
import {EcommerceRemoveFromCartEvent, EcommerceItem} from '../types';

type Props = {
  item: CartItem | undefined;
};

export function ecommerceRemoveFromCart({item}: Props): EcommerceRemoveFromCartEvent {
  const items: EcommerceItem[] =
    item?.variants.map((variant) => ({
      item_id: item.product.id,
      item_name: item.product.name ?? item.product.id,
      item_variant: variant.id,
      quantity: variant.quantity,
    })) ?? [];

  return {
    ecommerce: {
      items,
    },
    event: 'remove_from_cart',
  };
}
