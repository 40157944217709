import {AuthOrigin, AuthViewName} from 'components/Auth/types';
import {PriceRange} from 'components/PriceRange';
import {useAppEnv} from 'lib/appEnv';
import {useEvent, useStore} from 'lib/effector';
import {Money} from 'lib/pricing/types';
import {openAuthDialog} from 'models/auth/dialog';
import {$userAnonymous} from 'models/user';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import styles from './index.module.scss';
import LockIcon from './lock.svg';

const messages = defineMessages({
  label: {
    defaultMessage: 'See wholesale price',
    description: 'Locked wholesale price',
  },
});

type Props = {
  alwaysShowPrice?: boolean;
  price?: Money;
};

function LockedWholesalePrice(): React.ReactElement {
  const appEnv = useAppEnv();

  const openAuth = useEvent(openAuthDialog);
  const handleSignIn = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      openAuth({
        origin: AuthOrigin.SELF_SERVICE,
        source: appEnv.currentPageName,
        view: AuthViewName.SIGN_IN_BY_PHONE,
      });

      return false;
    },
    [openAuth, appEnv.currentPageName],
  );

  return (
    <button className={styles.button} onClick={handleSignIn} type='button'>
      <LockIcon className={styles.icon} />
      <FormattedMessage {...messages.label} />
    </button>
  );
}

// For an anonymous user, the price is hidden and backend does not send it
export function WholesalePrice({price, alwaysShowPrice}: Props) {
  const userAnonymous = useStore($userAnonymous);
  const showPrice = alwaysShowPrice || !userAnonymous;

  if (price && showPrice) {
    return <PriceRange currency={price.ccy} min={price.amount} />;
  }

  return showPrice ? null : <LockedWholesalePrice />;
}
