import React from 'react';
import {FormattedNumber} from 'react-intl';
import {ProductBadge, ProductBadgeTheme} from './ProductBadge';

type Props = {
  discount: number;
  theme?: ProductBadgeTheme;
};

export function DiscountBadge({discount, theme}: Props): React.ReactElement {
  return (
    <ProductBadge theme={theme}>
      <FormattedNumber minimumFractionDigits={0} style='percent' value={discount / -100} />
    </ProductBadge>
  );
}
