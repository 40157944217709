import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useRef} from 'react';
import {ArrowButton} from './ArrowButton';
import styles from './index.module.scss';
import {Shadow} from './Shadow';
import {useBoundaries} from './useBoundaries';

export type CarouseTestId = {
  leftButton: unknown;
  rightButton: unknown;
};

type Props = TestIdProp<CarouseTestId> & {
  children: React.ReactNode;
  withShadows?: boolean;
};

export const Carousel = ({children, testId, withShadows = false}: Props): React.ReactElement => {
  const slidesRef = useRef<HTMLUListElement>(null);
  const {isRightBoundaryReached, isLeftBoundaryReached} = useBoundaries(slidesRef);
  const slides = React.Children.toArray(children);
  const showArrowButtons = isRightBoundaryReached || isLeftBoundaryReached;

  const onLeftClick = useCallback(() => {
    if (slidesRef.current) {
      const {scrollLeft} = slidesRef.current;

      for (let i = slidesRef.current.children.length; i--; ) {
        const item = slidesRef.current.children[i] as HTMLElement;
        const itemOffset = item.offsetLeft;
        if (itemOffset < scrollLeft) {
          slidesRef.current.scrollLeft = item.offsetLeft;
          break;
        }
      }
    }
  }, []);

  const onRightClick = useCallback(() => {
    if (slidesRef.current) {
      const {scrollLeft, offsetWidth} = slidesRef.current;
      const rightOffset = scrollLeft + offsetWidth;

      for (let i = 0; i < slidesRef.current.children.length; i++) {
        const item = slidesRef.current.children[i] as HTMLElement;
        const itemOffset = item.offsetLeft + item.offsetWidth;
        if (itemOffset > rightOffset) {
          slidesRef.current.scrollLeft = item.offsetLeft - offsetWidth + item.offsetWidth;
          break;
        }
      }
    }
  }, []);

  return (
    <div className={styles.container}>
      <Shadow direction='right' visible={withShadows && isLeftBoundaryReached} />
      {showArrowButtons && (
        <ArrowButton
          disabled={!isLeftBoundaryReached}
          onClick={onLeftClick}
          position='left'
          testId={testId?.leftButton}
        />
      )}
      <ul className={styles.slides} ref={slidesRef}>
        {slides.map((slide, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.slide} key={idx}>
            {slide}
          </li>
        ))}
      </ul>
      {showArrowButtons && (
        <ArrowButton
          disabled={!isRightBoundaryReached}
          onClick={onRightClick}
          position='right'
          testId={testId?.rightButton}
        />
      )}
      <Shadow direction='left' visible={withShadows && isRightBoundaryReached} />
    </div>
  );
};
