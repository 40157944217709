import {SearchTag} from 'lib/searchTag/types';
import React from 'react';
import {ProductBadge} from './ProductBadge';

type Props = {
  tag: SearchTag;
};

export function SearchTagBadge({tag}: Props): React.ReactElement {
  return <ProductBadge theme={tag.tag}>{tag.title}</ProductBadge>;
}
