export const SchemaProps = {
  aggregateRating: {
    itemProp: 'aggregateRating',
    itemScope: true,
    itemType: 'http://schema.org/AggregateRating',
  },
  reviewRating: {
    itemProp: 'reviewRating',
    itemScope: true,
    itemType: 'http://schema.org/Rating',
  },
};
