import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {MarginalityMercado} from 'components/MarginalityMercado';
import {Price} from 'components/Price';
import {Money} from 'lib/pricing/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  retail: {
    defaultMessage: 'Retail price {price}',
    description: 'Product lite. Label for seller price',
  },
});

type Props = {
  marginPercent?: number;
  sellerPrice?: Money;
};

export function SellerPriceInfo({sellerPrice, marginPercent}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      {isNotNullish(sellerPrice) && (
        <div className={styles.price}>
          {intl.formatMessage(messages.retail, {
            price: <Price currency={sellerPrice.ccy} value={sellerPrice.amount} />,
          })}
        </div>
      )}
      {isNotNullish(marginPercent) && (
        <div className={styles.margin}>
          <MarginalityMercado kind='text' type='default' value={marginPercent} />
        </div>
      )}
    </div>
  );
}
