import {getCategoryHierarchy} from 'lib/analytics/gtm/category';
import {Category} from 'lib/category/types';
import {ProductLite} from 'lib/productLite/types';
import {EcommerceSelectItemEvent} from '../types';

type EcommerceProductClickParams = {
  category?: Category;
  listId?: string;
  listName?: string;
  position?: number;
  product: ProductLite;
};

export function ecommerceProductClick({
  category,
  listId,
  listName,
  position,
  product,
}: EcommerceProductClickParams): EcommerceSelectItemEvent {
  const categoryHierarchy = getCategoryHierarchy(category);
  return {
    ecommerce: {
      item_list_id: listId,
      item_list_name: listName,
      items: [
        {
          index: position,
          item_id: product.id,
          item_list_id: listId,
          item_list_name: listName,
          item_name: product.name ?? '',
          ...categoryHierarchy,
        },
      ],
    },
    event: 'select_item',
  };
}
