import {Price, PriceTestId} from 'components/Price';
import {isNumber} from 'lib/guards';
import {TestIdProp} from 'lib/testing/types';
import React, {ReactNode} from 'react';
import {defineMessages, FormattedMessage, IntlShape, useIntl} from 'react-intl';

let lastLocale = '';
let currencySignBehind = false;
function isCurrencySignBehind(intl: IntlShape): boolean {
  if (intl.locale !== lastLocale) {
    lastLocale = intl.locale;
    const value = intl.formatNumber(3, {currency: 'USD', style: 'currency'});
    currencySignBehind = value[0] !== '3';
  }

  return currencySignBehind;
}

const messages = defineMessages({
  from: {
    defaultMessage: 'from {min}',
    description: 'Price range with min value',
  },
  fromCapitalized: {
    defaultMessage: 'From {min}',
    description: 'Price range with min value (capitalized)',
  },
  fromTo: {
    defaultMessage: '{min} - {max}',
    description: 'Price range with min and max value',
  },
  to: {
    defaultMessage: 'to {max}',
    description: 'Price range with max value',
  },
  toCapitalized: {
    defaultMessage: 'To {max}',
    description: 'Price range with max value (capitalized)',
  },
});

export type PriceRangeTestId = {
  maxPrice: PriceTestId;
  minPrice: PriceTestId;
  singlePrice: PriceTestId;
};

type Props = TestIdProp<PriceRangeTestId> & {
  capitalize?: boolean;
  children?: ReactNode;
  currency: string;
  max?: number;
  min?: number;
  noSchema?: boolean;
  noText?: boolean;
};

function isNumberSupported(value?: number): value is number {
  return isNumber(value) && Number.isFinite(value) && !Number.isNaN(value);
}

export function PriceRange({
  capitalize = false,
  children,
  max,
  min,
  currency,
  noSchema,
  noText,
  testId,
}: Props): React.ReactElement {
  const intl = useIntl();
  const minValid = isNumberSupported(min);
  const maxValid = isNumberSupported(max);

  if (minValid && maxValid) {
    const minCurrency = isCurrencySignBehind(intl) ? currency : '';
    const maxCurrency = isCurrencySignBehind(intl) ? '' : currency;

    if (min === max) {
      return (
        <Price
          currency={currency}
          key='singlePrice'
          noSchema={noSchema}
          noText={noText}
          testId={testId?.singlePrice}
          value={min!}
        />
      );
    }

    return (
      <FormattedMessage
        {...messages.fromTo}
        values={{
          max: (
            <Price
              currency={maxCurrency}
              key='max'
              noSchema={noSchema}
              noText={noText}
              testId={testId?.maxPrice}
              value={max!}
            />
          ),
          min: (
            <Price
              currency={minCurrency}
              key='min'
              noSchema={noSchema}
              noText={noText}
              testId={testId?.minPrice}
              value={min!}
            />
          ),
        }}
      />
    );
  }

  if (minValid) {
    const message = capitalize ? messages.fromCapitalized : messages.from;
    return (
      <FormattedMessage
        {...message}
        values={{
          min: <Price currency={currency} noSchema={noSchema} noText={noText} testId={testId?.minPrice} value={min!} />,
        }}
      />
    );
  }

  if (maxValid) {
    const message = capitalize ? messages.toCapitalized : messages.to;
    return (
      <FormattedMessage
        {...message}
        values={{
          max: <Price currency={currency} noSchema={noSchema} noText={noText} testId={testId?.maxPrice} value={max!} />,
        }}
      />
    );
  }

  // bad min and max
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}
