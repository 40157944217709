import React from 'react';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
};

export function Square({children}: Props): React.ReactElement {
  return (
    <div className={styles.square}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
