import {isNumber} from 'lib/guards';
import {ObfuscatedCount} from './types';

export function getObfuscatedCountValue(count: ObfuscatedCount): number {
  if (isNumber(count)) {
    return count;
  }

  return count.value;
}
