import React from 'react';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
};

export function NoticeItem({children}: Props): React.ReactElement {
  return <div className={styles.noticeItem}>{children}</div>;
}
