import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

export type ProductBadgeTheme = 'default' | 'filled' | 'bordered' | 'topProduct' | 'hotPrice';

const BADGE_CLASSNAMES: Record<ProductBadgeTheme, string> = {
  bordered: styles.borderedBadge,
  default: styles.defaultBadge,
  filled: styles.filledBadge,
  hotPrice: styles.hotPriceBadge,
  topProduct: styles.topProductBadge,
};

type ProductBadgeProps = {
  children: React.ReactNode;
  theme?: ProductBadgeTheme;
};

export function ProductBadge({theme = 'default', children}: ProductBadgeProps): React.ReactElement {
  return <div className={cn(styles.badge, BADGE_CLASSNAMES[theme])}>{children}</div>;
}
