import {CartItem} from 'lib/cart/types';
import {CartItemInfo} from 'lib/deal/types';
import {EcommerceAddShippingInfoEvent, EcommerceItem} from '../types';

type Props = {
  cartItems: CartItem[];
  cartItemsData: CartItemInfo[];
};

export function ecommerceAddShippingInfo({cartItems, cartItemsData}: Props): EcommerceAddShippingInfoEvent {
  const cartItemIdToDescription = new Map<string, string | undefined>();
  for (const item of cartItemsData) {
    cartItemIdToDescription.set(item.cartItemId, item.customizationDescription);
  }
  const items: EcommerceItem[] = cartItems.flatMap((item) => {
    const description = cartItemIdToDescription.get(item.id);
    return item.variants.map(
      (variant): EcommerceItem => ({
        comment_length: Math.ceil((description?.length ?? 0) / 50) * 50,
        item_id: variant.productId,
        item_name: variant.productId,
        item_variant: variant.id,
        quantity: variant.quantity,
      }),
    );
  });
  return {
    ecommerce: {
      items,
    },
    event: 'add_shipping_info',
  };
}
