import {assertNever} from '@joomcode/deprecated-utils/types';
import {ProductBadge} from 'lib/product/types';
import {HotSale} from './HotSale';
import {MercadoBestSeller} from './MercadoBestSeller';

export {BrandBadge} from './BrandBadge';
export {DiscountBadge} from './DiscountBadge';
export {SearchTagBadge} from './SearchTagBadge';

export type Props = {
  badge: ProductBadge;
};

export function ProductBadgeFactory({badge}: Props): React.ReactElement | null {
  switch (badge) {
    case ProductBadge.HOT_SALE:
      return <HotSale />;
    case ProductBadge.MERCADO_BEST_SELLER:
      return <MercadoBestSeller />;
    default:
      assertNever(badge);
      return null;
  }
}
