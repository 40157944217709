import {CartItem} from 'lib/cart/types';
import {EcommerceViewCartEvent, EcommerceItem} from '../types';

type Props = {
  cartItems: CartItem[];
};

export function ecommerceViewCart({cartItems}: Props): EcommerceViewCartEvent {
  const items: EcommerceItem[] = cartItems.flatMap((item) =>
    item.variants.map((variant) => ({
      item_id: item.product.id,
      item_name: item.product.name ?? item.product.id,
      item_variant: variant.id,
      quantity: variant.quantity,
    })),
  );
  return {
    ecommerce: {
      items,
    },
    event: 'view_cart',
  };
}
